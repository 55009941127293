.external-links li:not(:first-child) {
  margin-left: 1em !important;
}

.external-links {
  position: absolute;
  bottom: -1.3em;
  right: 0;

  font-style: italic;
  cursor: pointer;
  text-align: right;
  font-size: 1em;
  text-decoration: underline;
}

.checkbox-external-links {
  position: initial;
  margin-left: 1em;
}

.link-without-error {
  bottom: -1.5em;
}

.right-0-9 {
  right: 0.9em !important;
}

.select-option-restyle select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, #adb5bd 50%),
    linear-gradient(135deg, #adb5bd 50%, transparent 50%),
    linear-gradient(to right, #adb5bd, #adb5bd);
  background-position: calc(100% - 1.4em) calc(1.3em + 2px),
    calc(100% - 0.93em) calc(1.3em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 0.5em 0.5em, 0.5em 0.5em, 0.08em 2.2em;
  background-repeat: no-repeat;
}

.text-red {
  color: #fb6340;
}

.refresh-handler {
  cursor: pointer;
  font-size: 1.2em !important;
  background: transparent !important;
}

.outline-0:focus {
  outline: 0;
}

.refresh-col {
  margin-left: -0.9em;
  padding: 0;
  margin-top: -1.5em;
  align-self: center;
}

.padding-0 {
  padding: 0 !important;
}

span.input-group-text {
  border-left: 0;
}

.input-group .form-control:not(:last-child) {
  border-right: 1px solid #cad1d7 !important;
}

.input-group .is-invalid:not(:last-child) {
  border-right: 1px solid #fb6340 !important;
}

.brtb {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.tooltip-invalid span {
  border-color: #fb6340;
  color: #fb6340;
}

.multi-select-flex .select__control {
  min-height: 2.87em !important;
}

.multi-select-flex .basic-multi-select.bg-transparent .select__control {
  background-color: transparent !important;
}

.multi-select-flex .basic-multi-select {
  flex-grow: 1;
  /* max-width: calc(100% - 2.49em); */
}

.multi-select-flex .input-with-info-refersh .select__control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.multi-select-invalid .select__control,
.multi-select-invalid:hover .select__control,
.border-red {
  border-color: #fb6340 !important;
}

.multi-select-invalid .select__control--menu-is-open,
.multi-select-invalid .select__control--is-focused {
  box-shadow: 0 0 0 1px #fb6340;
}

.custom-dropdown-title {
  font-style: italic;
  text-decoration: underline !important;
}

.header .custom-dropdown-menu {
  max-height: 220px;
  overflow-y: auto;
  box-shadow: 0 3px 6px #00000066;
}
.cursor-pointer {
  cursor: pointer;
}

.radio-horizontal {
  display: flex;
  flex-wrap: wrap;
}

.custom-control-label {
  font-size: 16px !important;
  text-transform: capitalize;
  padding-top: 0.25em;
}

.custom-input-border {
  border: 1px solid #cad1d7;
  border-radius: 5px;
  padding: 12px;
}

.btn-pill {
  color: #5e72e4;
  background-color: #fff;
  border-color: #5e72e4;
  border-radius: 2.125em;
  border: 0.125em solid;
  margin-right: 0 !important;
}

.pill-condition {
  align-self: center;
  display: inline-block;
  padding: 0 1em;
}

.btn-pill.active {
  background-color: #5e72e4 !important;
  border-color: #5e72e4 !important;
}

.tc-pill-active {
  display: inline-block;
  border-radius: 10rem;
}

.custom-control-label {
  padding-top: 0;
}
