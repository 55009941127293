
.super-table {
  width: 100%;
}
.super-table-header {
}

.super-table-header-cell {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer !important;
}

.super-table-action-dropdown::after {
  display: none !important;
}

.super-table td {
  vertical-align: middle !important;
}

.super-table thead th {
  text-transform: uppercase;
}


.super-table .breadcrumb {
  margin-bottom: 0 !important;
}


.shimmer {
  height: 20px;
  width: 100px;
  border-radius: 3px;
  background: linear-gradient(270deg, #c1c1c1, #f3f3f3, #d7d4d4);
  background-size: 600% 600%;
  -webkit-animation: shimmer-effect  2s ease infinite;
  -moz-animation: shimmer-effect  2s ease infinite;
  animation: shimmer-effect  2s ease infinite;
}

@-webkit-keyframes shimmer-effect {
  0% {
    background-position: 0 40%;
  }
  50% {
    background-position: 100% 61%;
  }
  100% {
    background-position: 0 40%;
  }
}
@-moz-keyframes shimmer-effect {
  0% {
    background-position: 0 40%;
  }
  50% {
    background-position: 100% 61%;
  }
  100% {
    background-position: 0 40%;
  }
}
@keyframes shimmer-effect {
  0% {
    background-position: 0 40%;
  }
  50% {
    background-position: 100% 61%;
  }
  100% {
    background-position: 0 40%;
  }
}




.resize-table th {
  /*position: -webkit-sticky;*/
  /*position: sticky;*/
  top: 0;
  position: relative;
  border: 2px solid #dee2e6;
  user-select: none;
}


.resize-table .resize-handle {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  background: black;
  opacity: 0;
  width: 3px;
  cursor: col-resize;
}

.resize-table .resize-handle:hover,
.resize-table .header--being-resized .resize-handle {
  opacity: 0.5;
}

.resize-table th:hover .resize-handle {
  opacity: 0.3;
}
